import React from 'react';
import type { FC } from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDog, faUserMd } from '@fortawesome/free-solid-svg-icons';
import { Layout } from '../components/layout';
import type { IndexPageQuery } from 'graphql-types';
import { SEO } from '../components/seo';
import { ConsultationHours } from '../components/consultationHours';
import { Phone } from '../components/phone';
import { StaticImage } from 'gatsby-plugin-image';
import { KeyVisual } from '../components/keyVisual';

interface PageProps {
  data: IndexPageQuery;
}

const Page: FC<PageProps> = ({ data }) => (
  <Layout>
    <SEO keywords="犬と猫の腫瘍科専門診療" />
    <KeyVisual />
    <p className="key-message">
      私たちは犬と猫の腫瘍科・がん診療に特化した動物病院です
    </p>
    <p className="link-to-about">
      <Link to="/about">当院について&nbsp;&gt;</Link>
    </p>
    <div className="key02">
      <StaticImage
        src="../images/logo01.png"
        alt="動物がんクリニック東京"
        placeholder="blurred"
        css={css`
          width: 80vw;
        `}
      />
    </div>
    <div className="first-content" css={layout}>
      <div className="news">
        <h2>お知らせ</h2>
        <p className="news-item" css={item}>
          {data.allMarkdownRemark.edges.map(edge => {
            if (!edge.node.frontmatter?.slug) return null;

            // const slug = edge.node.frontmatter.slug;

            return (
              <>
                {edge.node.frontmatter.date && (
                  <p>{edge.node.frontmatter.date}</p>
                )}
                <p>{edge.node.frontmatter.title ?? '(無題)'}</p>
              </>
            );
          })}
        </p>
      </div>
    </div>
    <div className="guidance">
      <h2>ご案内</h2>
      <div className="guidance-inner">
        <Link to="/owner">
          <FontAwesomeIcon icon={faDog} size={'3x'} color={'#496db7'} />
          <p className="heading">飼い主の皆様へ</p>
          <p className="content">
            腫瘍・がんのサイン
            <br />
            診療の流れ
          </p>
          <p className="more">Read more</p>
        </Link>
        <Link to="/doctor">
          <FontAwesomeIcon icon={faUserMd} size={'3x'} color={'#496db7'} />
          <p className="heading">獣医師の皆様へ</p>
          <p className="content">ご予約方法のご案内</p>
          <p className="more">Read more</p>
        </Link>
      </div>
    </div>
    <div className="medical-detail">
      <h2>診療内容</h2>
      <div className="medical-detail-inner">
        <Link to="/clinical-examination" className="medical-detail-item">
          <p>腫瘍・がんの診断・がん検診</p>
          <StaticImage
            src="../images/clinical-examination.jpg"
            alt="腫瘍・がんの診断"
            placeholder="blurred"
          />
        </Link>
        <Link to="/oncology-surgery" className="medical-detail-item">
          <p>腫瘍外科・救急外科</p>
          <StaticImage
            src="../images/oncology-surgery.jpg"
            alt="腫瘍外科・救急外科"
            placeholder="blurred"
          />
        </Link>
        <Link to="/chemotherapy" className="medical-detail-item">
          <p>化学療法</p>
          <StaticImage
            src="../images/chemotherapy.jpg"
            alt="化学療法"
            placeholder="blurred"
          />
        </Link>
        <Link to="/radiation-therapy" className="medical-detail-item">
          <p>放射線治療コンサルテーション</p>
          <StaticImage
            src="../images/radiation-therapy.JPG"
            alt="放射線治療コンサルテーション"
            placeholder="blurred"
          />
        </Link>
        <Link to="/palliative-care" className="medical-detail-item">
          <p>緩和ケア</p>
          <StaticImage
            src="../images/palliative-care.jpg"
            alt="緩和ケア"
            placeholder="blurred"
          />
        </Link>
        <Link to="/case-report" className="medical-detail-item">
          <p>症例報告</p>
          <StaticImage
            src="../images/case-report.jpg"
            alt="症例報告"
            placeholder="blurred"
          />
        </Link>
        <Link to="/research-achievement" className="medical-detail-item">
          <p>学術実績</p>
          <StaticImage
            src="../images/research-achievement.jpg"
            alt="学術実績"
            placeholder="blurred"
          />
        </Link>
        <Link to="/blood-donors" css={medicalDetailItem}>
          <p>献血ドナー募集中</p>
          <StaticImage
            src="../images/blood-donors.png"
            alt="献血ドナー募集中"
            placeholder="blurred"
          />
        </Link>
      </div>
    </div>
    <p css={linkToRecruit}>
      <Link to="/recruit">採用情報&nbsp;&nbsp;Recruit</Link>
    </p>
    <div className="medical-and-access">
      <div className="medical">
        <div className="heading">診療時間</div>
        <div>
          <ConsultationHours color={'light'} />
        </div>
        <div className="heading">予約</div>
        <div css={phoneWrapper}>
          <Phone color={'light'} />
        </div>
        <div>当院は予約優先制となります。</div>
        <div className="heading">お支払い方法</div>
        {/* eslint-disable-next-line no-irregular-whitespace */}
        <div>現金　各種クレジットカード</div>
      </div>
      <div className="access">
        <StaticImage
          src="../images/map.png"
          alt="病院までの地図"
          placeholder="blurred"
        />
        <Link to="/access" className="google-map">
          大きい地図で確認する
        </Link>
        <p>
          〒135-0004 東京都江東区森下3-13-5 1F
          <br />
          <span css={red}>専用駐車場2台あります</span>
          <br />
          ※専用駐車場が満車の場合は病院隣のTIMESパーキングをご利用ください。1時間までのサービスチケットをお渡しします。
        </p>
      </div>
    </div>
  </Layout>
);
export default Page;

const layout = css`
  padding: 1rem 4rem;

  @media (max-width: 560px) {
    padding: 1rem !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }
`;

const item = css`
  @media (max-width: 560px) {
    width: 100% !important; // TODO: index.scss撲滅 撲滅次第importantを削除する
  }
`;

const phoneWrapper = css`
  padding: 1rem 0;
  font-size: 1.5rem;
`;

const linkToRecruit = css`
  text-align: center;
  margin-bottom: .4rem;

  @media screen and (max-width: 560px) {
    margin-bottom: 1rem;
  }

  a {
    border: solid 1px #024387;
    background-color: #024387;
    border-radius: 15px;
    padding: .5rem 1.5rem;
    font-size: 2rem;
    color: #fff;

    @media screen and (max-width: 560px) {
      font-size: 1rem;
      line-height: 1rem;
    }
  }
`;

const medicalDetailItem = css`
  width: 80%;
  margin: 0 auto 4rem;

  p {
    margin-bottom: .5rem;
  }

  img {
    width: 15rem;
    height: 15rem;
    object-fit: cover;
    border-radius: 50%;
    border: solid 1px #ccc;
  }
`;

const red = css`
    color: #e66a6a;
    font-weight: bold;
    font-size: 1.2rem;
`;


export const query = graphql`
  query IndexPage {
    allMarkdownRemark(
      filter: { frontmatter: { slug: { regex: "^/news/" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            date(formatString: "YYYY.MM.DD", locale: "ja-JP")
            slug
            title
          }
        }
      }
    }
  }
`;

