import React from 'react';
import type { FC } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

export const KeyVisual: FC = () => {
  const breakpoints = useBreakpoint();
  console.log('key visual start');
  console.log(breakpoints);

  return breakpoints.sp ? (
    <Carousel
      infiniteLoop={true}
      autoPlay={true}
      interval={6000}
      centerMode={true}
      centerSlidePercentage={80}
      stopOnHover={false}
      showStatus={false}
      showThumbs={false}
    >
      <div>
        <StaticImage
          src="../images/key01_sp.jpg"
          alt="芝生を駆ける犬のキービジュアル"
          placeholder="blurred"
        />
      </div>
      <div>
        <StaticImage
          src="../images/key02_sp.jpg"
          alt="病院の入り口のキービジュアル"
          placeholder="blurred"
        />
      </div>
      <div>
        <StaticImage
          src="../images/key03_sp.jpg"
          alt="手術の様子のキービジュアル"
          placeholder="blurred"
        />
      </div>
      <div>
        <StaticImage
          src="../images/key04_sp.jpg"
          alt="猫のキービジュアル"
          placeholder="blurred"
        />
      </div>
    </Carousel>
  ) : (
    <div>
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        interval={6000}
        centerMode={true}
        centerSlidePercentage={50}
        stopOnHover={false}
        showStatus={false}
        showThumbs={false}
      >
        <div>
          <StaticImage
            src="../images/key01.jpg"
            alt="芝生を駆ける犬のキービジュアル"
            placeholder="blurred"
          />
        </div>
        <div>
          <StaticImage
            src="../images/key02.jpg"
            alt="病院の入り口のキービジュアル"
            placeholder="blurred"
          />
        </div>
        <div>
          <StaticImage
            src="../images/key03.jpg"
            alt="手術の様子のキービジュアル"
            placeholder="blurred"
          />
        </div>
        <div>
          <StaticImage
            src="../images/key04.jpg"
            alt="猫のキービジュアル"
            placeholder="blurred"
          />
        </div>
      </Carousel>
    </div>
  );
};
